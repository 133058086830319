import React from "react";
import { Link } from "gatsby";
import axios from "axios";
import HashLoader from "react-spinners/HashLoader";
import { StaticImage } from "gatsby-plugin-image";

const Footer = ({ isTopborder }) => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorMsg, setMsg] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const _handleChange = (e) => {
    setError(false);
    setSuccess(false);
    setEmail(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      setLoading(true);
      const today = new Date();
      const data = { Emails: email, Date: today.toLocaleDateString("en-US") };
      axios
        .post("https://leads.civsav.com/seocannabis", {
          data,
        })
        .then(() => {
          setEmail("");
          setLoading(false);
          setSuccess(true);
        })
        .catch(() => {
          setLoading(false);
          setMsg("Something went wrong!");
          setError(true);
        });
    } else {
      setMsg("Email Address Required!");
      setError(true);
    }
  };
  return (
    <footer
      class={`globalFooter container ${isTopborder ? "-top-border" : ""} `}
      role="contentinfo"
    >
      <div class="globalFooter-content">
        <a
          class="globalFooter-logo center"
          href="/"
          title="Go to SEO Cannabis homepage"
        >
          <div class="logo">
            <StaticImage
              src="../../assets/images/logo.png"
              placeholder="blurred"
              width={50}
            />
          </div>
        </a>
        <div class="globalFooter-copyright center">
          <p>© SEO Cannabis 2022</p>
          <Link to="/contact#form">Contact US</Link>
        </div>
        <div class="globalFooter-navigation">
          <nav class="navigation -footer" aria-labelledby="footer_navLabel">
            <p id="footer_navLabel" class="_hidden">
              Footer Navigation
            </p>

            <ul class="navigation-list">
              <li class="navigation-listItem">
                <Link to="/dispensary-seo" className="navigation-link">
                  Dispensary SEO
                </Link>
              </li>
              <li class="navigation-listItem">
                <Link to="/delivery-cannabis-seo" className="navigation-link">
                  Delivery & E-Comm
                </Link>
              </li>
              <li class="navigation-listItem">
                <Link to="/delta-8-seo" className="navigation-link">
                  Delta 8 Seo
                </Link>
              </li>
              <li class="navigation-listItem">
                <Link to="/contact" className="navigation-link">
                  Initiate A Conversation
                </Link>
              </li>
              <li class="navigation-listItem">
                <Link to="/contact" className="navigation-link">
                  {" "}
                  Online Reputation Management
                </Link>
              </li>
            </ul>
            <div class="nav-social _mobile-only">
              <a
                class="-color-light-gray globalFooter-link"
                href="mailto:info@seocannabis.agency"
                title="Reach out to Civilized Savage"
              >
                info@seocannabis.agency
              </a>
              <form
                id="footer_navigation_mc_embed_signup"
                action="  https://docs.google.com/forms/d/e/1FAIpQLSffxOiLlfSU-QZ5GDcsH3v3XBzHUttMbw6YGL278S095MDAbg/viewform"
                method="get"
                name="mc-embedded-subscribe-form"
                class="newsletter -small validate"
                target="_blank"
                novalidate=""
              >
                <div id="footer_navigation_mc_embed_signup_scroll">
                  <div class="mc-field-group">
                    <label
                      htmlFor="footer_navigation_mce-EMAIL"
                      class="_hidden"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      class="newsletter-input -small required email"
                      id="footer_navigation_mce-EMAIL"
                      placeholder="Enter Email for Updates"
                    />
                  </div>
                  <div id="footer_navigation_mce-responses" class="clear">
                    <div
                      class="response"
                      id="footer_navigation_mce-error-response"
                      style={{ display: "none" }}
                    ></div>
                    <div
                      class="response"
                      id="footer_navigation_mce-success-response"
                      style={{ display: "none" }}
                    ></div>
                  </div>
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      name="footer_navigation_b_569c8d937524ae5126266001f_a41ea28390"
                      tabindex="-1"
                    />
                  </div>
                  <a
                    href="  https://docs.google.com/forms/d/e/1FAIpQLSffxOiLlfSU-QZ5GDcsH3v3XBzHUttMbw6YGL278S095MDAbg/viewform"
                    target="_blank"
                  >
                    <button
                      class="newsletter-submit"
                      type="submit"
                      id="footer_navigation_mc-embedded-subscribe"
                    >
                      <span class="_hidden">Subscribe</span>
                      <svg
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="#BBBBBE"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6.81016 12.8243H8.89016L15.1622 6.55227L8.89016 0.280273H6.81016L12.3302 5.78427H0.410156V7.32027H12.3302L6.81016 12.8243Z"></path>
                      </svg>
                    </button>
                  </a>
                </div>
              </form>
            </div>
          </nav>
        </div>
        <div class="globalFooter-right">
          <div class="globalFooter-social">
            <a
              class="-color-light-gray globalFooter-link"
              href="mailto:info@seocannabis.agency"
              title="Reach out to Civilized Savage"
            >
              info@seocannabis.agency
            </a>
            <form
              id="footer_mc_embed_signup"
              name="mc-embedded-subscribe-form"
              class="newsletter -small validate"
              target="_blank"
              novalidate=""
              onSubmit={handleSubmit}
            >
              <div id="footer_mc_embed_signup_scroll">
                <div class="mc-field-group">
                  <label htmlFor="footer_mce-EMAIL" class="_hidden">
                    Email Address
                  </label>
                  <input
                    type="email"
                    class="newsletter-input -small required email"
                    id="footer_mce-EMAIL"
                    placeholder="Enter Email for Updates"
                    style={{ marginBottom: 3, color: "#fff" }}
                    onChange={_handleChange}
                  />
                </div>
                <div class="mc-field-group">
                  {error && (
                    <label htmlFor="message" className="_error">
                      {errorMsg}
                    </label>
                  )}
                  {success && (
                    <label htmlFor="message" className="_success">
                      Successfully Subscribed!
                    </label>
                  )}
                </div>
                <div id="footer_mce-responses" class="clear">
                  <div
                    class="response"
                    id="footer_mce-error-response"
                    style={{ display: "none" }}
                  ></div>
                  <div
                    class="response"
                    id="footer_mce-success-response"
                    style={{ display: "none" }}
                  ></div>
                </div>
                <div
                  style={{ position: "absolute", left: "-5000px" }}
                  aria-hidden="true"
                ></div>
                <button
                  class="newsletter-submit"
                  type="submit"
                  id="footer_mc-embedded-subscribe"
                  disabled={loading}
                >
                  {loading ? (
                    <HashLoader size={20} color={"#D5B795"} />
                  ) : (
                    <>
                      <span class="_hidden">Subscribe</span>
                      <svg
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="#BBBBBE"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6.81016 12.8243H8.89016L15.1622 6.55227L8.89016 0.280273H6.81016L12.3302 5.78427H0.410156V7.32027H12.3302L6.81016 12.8243Z"></path>
                      </svg>
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
